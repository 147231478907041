<template>
  <div>
    <div class="dropdown">
      <button
        class="btn btn-info dropdown-toggle btn-sm py-2 float-right"
        type="button"
        id="dropdownMenuButton-week"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Add New
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton-week">
        <router-link
          :to="{ name: 'admin.correlation.direct' }"
          class="dropdown-item"
          >Direct</router-link
        >
        <router-link
          :to="{ name: 'admin.correlation.create' }"
          class="dropdown-item"
          >Manually</router-link
        >
      </div>
    </div>
    <h2 class="h3">Correlation Analysis</h2>
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <div v-for="(group, i) in dates" :key="`date-group-${i}`">
              <select
                v-model="show_id"
                class="form-control"
                @change="getGraph()"
                v-if="correlation_type == i"
              >
                <option
                  v-for="(option, j) in group"
                  :key="`group--${i}-${j}`"
                  :value="option.id"
                >
                  {{ option.date }} - {{ option.diff }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-4">
            <select v-model="correlation_type" class="form-control">
              <option value="5-min">5 Minutes</option>
              <option value="15-min">15 Minutes</option>
              <option value="1-hour">1 Hour</option>
              <option value="4-hour">4 Hour</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="6-month">6 Months</option>
              <option value="12-month">12 Months</option>
            </select>
          </div>
        </div>
        <template v-if="loaded">
          <div class="row justify-content-center mt-5">
            <div class="col-md-12 text-right">
              <a href="#" class="btn btn-danger" @click.prevent="destroy"><i class="lni lni-trash-can mr-2"></i>Delete</a>
            </div>
            <div class="col-md-8">
              <chart :data="graph_data" type="bar" :height="`200px`" />
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-4">
              <h3 class="h4">Strong</h3>
              <ol>
                <li v-for="(strong, i) in analysis.strong" :key="`strong-${i}`">
                  {{ strong }}
                </li>
              </ol>
            </div>
            <div class="col-md-4">
              <h3 class="h4">Neutral</h3>
              <ol>
                <li
                  v-for="(neutral, i) in analysis.neutral"
                  :key="`neutral-${i}`"
                >
                  {{ neutral }}
                </li>
              </ol>
            </div>
            <div class="col-md-4">
              <h3 class="h4">Weak</h3>
              <ol>
                <li v-for="(weak, i) in analysis.weak" :key="`weak-${i}`">
                  {{ weak }}
                </li>
              </ol>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <h4>Further Analysis</h4>
              <table class="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>1 Hour</th>
                    <th>4 Hour</th>
                    <th>Daily</th>
                    <th>Weekly</th>
                    <th>Monthly</th>
                    <th>6 Month</th>
                    <th>12 Month</th>
                    <th>Majority</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="correlation strong">
                    <td>Strong</td>
                    <td v-for="(value, key) in table" :key="`table-${key}`">
                      <p
                        v-for="(item, i) in value['strong']"
                        :key="`table-${key}-${i}`"
                      >
                        {{ item }}
                      </p>
                    </td>
                    <td>{{ majority_strong }}</td>
                  </tr>
                  <tr class="correlation neutral">
                    <td>Neutral</td>
                    <td v-for="(value, key) in table" :key="`table-${key}`">
                      <p
                        v-for="(item, i) in value['neutral']"
                        :key="`table-${key}-${i}`"
                      >
                        {{ item }}
                      </p>
                    </td>
                    <td>{{ majority_neutral }}</td>
                  </tr>
                  <tr class="correlation weak">
                    <td>Weak</td>
                    <td v-for="(value, key) in table" :key="`table-${key}`">
                      <p
                        v-for="(item, i) in value['weak']"
                        :key="`table-${key}-${i}`"
                      >
                        {{ item }}
                      </p>
                    </td>
                    <td>{{ majority_weak }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <div v-if="!loaded" class="text-center py-5">
          <img src="/loader.gif" alt="logo" style="height: 100px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/charts/Chart";

export default {
  components: {
    Chart,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      show_id: 0,
      dates: [],
      loaded: false,
      graph_data: {},
      correlation_type: "1-hour",
      analysis: {},
      table: [],
    };
  },

  methods: {
    fetch() {
      this.$loader.start();

      this.$axios.get("/api/v1/admin/correlation").then((response) => {
        this.dates = response.data.dates;
        this.table = response.data.analysis;
        this.show_id = response.data.dates['1-hour'][0].id;
        this.$loader.stop();
      });
    },

    getGraph() {
      this.loaded = false;

      this.$axios
        .get(`/api/v1/admin/correlation/${this.show_id}`)
        .then((response) => {
          this.graph_data = response.data.graph_data;
          this.analysis = response.data.analysis;
          this.loaded = true;
        });
    },

    destroy() {
      this.$axios
        .delete(`/api/v1/admin/correlation/${this.show_id}`)
        .then(() => {
          this.fetch()
        });
    }
  },

  computed: {
    majority_strong() {
      let arr = [];

      this.table.forEach((col) => {
        if (col["strong"]) {
          col["strong"].forEach((cur) => { 
            if (arr[cur]) {
              arr[cur] += 1;
            } else {
              arr[cur] = 1;
            }
          });
        }
      });
      
      let sort = Object.values(arr).sort((a, b) => { return b - a })
      let sorted = Object.values(arr).indexOf(sort[0])

      return Object.keys(arr)[sorted];
    },

    majority_neutral() {
      let arr = [];

      this.table.forEach((col) => {
        if (col["neutral"]) {
          col["neutral"].forEach((cur) => { 
            if (arr[cur]) {
              arr[cur] += 1;
            } else {
              arr[cur] = 1;
            }
          });
        }
      });
      
      let sort = Object.values(arr).sort((a, b) => { return b - a })
      let sorted = Object.values(arr).indexOf(sort[0])

      return Object.keys(arr)[sorted];
    },

    majority_weak() {
      let arr = [];

      this.table.forEach((col) => {
        if (col["weak"]) {
          col["weak"].forEach((cur) => { 
            if (arr[cur]) {
              arr[cur] += 1;
            } else {
              arr[cur] = 1;
            }
          });
        }
      });
      
      let sort = Object.values(arr).sort((a, b) => { return b - a })
      let sorted = Object.values(arr).indexOf(sort[0])

      return Object.keys(arr)[sorted];
    },
  },

  watch: {
    show_id() {
      this.getGraph();
    },

    correlation_type(newval) {
      if (this.dates[newval]) {
        this.show_id = this.dates[newval][0].id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.correlation, .correlation p {
color: #404040 !important;
}

.correlation.strong {
  background: #2c994a;
}

.correlation.neutral {
  background: yellow;
}

.correlation.weak {
  background: #ed1c25;
}
</style>